
//====================MODAL WINDOW===========================

document.addEventListener('turbo:load', function () {
	let popupOverlay = document.querySelector('.modal-window');
	let closeBtn = document.querySelector('.close-modal');
	let openPopupBtns = document.querySelectorAll('.modal-icon');

	if (document.querySelector('.modal-window')) {
		function openPopup(event) {
			// Додавання класу active до натиснутого елемента
			openPopupBtns.forEach(function (btn) {
				btn.classList.remove('active');
			});
			event.currentTarget.classList.add('active');

			popupOverlay.style.visibility = 'visible';
		}

		function closePopup() {
			openPopupBtns.forEach(function (btn) {
				btn.classList.remove('active');
			});

			popupOverlay.style.visibility = 'hidden';
		}

		closeBtn.addEventListener('click', closePopup);

		popupOverlay.addEventListener('click', function (event) {
			if (event.target === popupOverlay) {
				closePopup();
			}
		});

		openPopupBtns.forEach(function (btn) {
			btn.addEventListener('click', openPopup);
		});
	}
});



//====================Product image position while hovering===========================

// document.addEventListener('turbo:load', function () {
// 	if (window.innerWidth > 1024) {
// 		const productsContents = document.querySelectorAll('.products-content');

// 		productsContents.forEach(container => {
// 			const cells = container.querySelectorAll('.cell');

// 			cells.forEach(cell => {
// 				cell.addEventListener('mouseenter', function () {
// 					const productImage = this.querySelector('.product-image');

// 					const cellRect = cell.getBoundingClientRect();
// 					const imageRect = productImage.getBoundingClientRect();
// 					const containerRect = container.getBoundingClientRect();

// 					const spaceAbove = cellRect.top - containerRect.top;
// 					const spaceBelow = containerRect.bottom - cellRect.bottom;
// 					const spaceLeft = cellRect.left - containerRect.left;
// 					const spaceRight = containerRect.right - cellRect.right;

// 					let top, left;

// 					if (spaceBelow >= imageRect.height) {
// 						top = cellRect.height;
// 						left = 0;
// 					} else if (spaceAbove >= imageRect.height) {
// 						top = -imageRect.height;
// 						left = 0;
// 					} else if (spaceRight >= imageRect.width) {
// 						top = 0;
// 						left = cellRect.width;
// 					} else if (spaceLeft >= imageRect.width) {
// 						top = 0;
// 						left = -imageRect.width;
// 					} else {
// 						top = cellRect.height;
// 						left = 0;
// 					}

// 					productImage.style.top = `${top}px`;
// 					productImage.style.left = `${left}px`;

// 					const productImageNewRect = productImage.getBoundingClientRect();

// 					if (productImageNewRect.bottom > containerRect.bottom) {
// 						top -= productImageNewRect.bottom - containerRect.bottom;
// 					}
// 					if (productImageNewRect.top < containerRect.top) {
// 						top += containerRect.top - productImageNewRect.top;
// 					}
// 					if (productImageNewRect.right > containerRect.right) {
// 						left -= productImageNewRect.right - containerRect.right;
// 					}
// 					if (productImageNewRect.left < containerRect.left) {
// 						left += containerRect.left - productImageNewRect.left;
// 					}

// 					productImage.style.top = `${top}px`;
// 					productImage.style.left = `${left}px`;
// 				});

// 				cell.addEventListener('mouseleave', function () {
// 					const productImage = this.querySelector('.product-image');
// 					productImage.style.top = '';
// 					productImage.style.left = '';
// 				});
// 			});
// 		});
// 	}
// });


//====================Product image position ADAPTVE===========================

// document.addEventListener('turbo:load', () => {
// 	const handleCellClick = (event) => {
// 		const targetClass = event.currentTarget.getAttribute('data-target');

// 		if (targetClass) {
// 			const targetElement = document.querySelector(`.product-image.${targetClass}`);

// 			if (targetElement) {
// 				document.querySelectorAll('.product-image').forEach(image => {
// 					image.classList.remove('active');
// 				});

// 				targetElement.classList.add('active');
// 			}
// 		}
// 	};

// 	const handleCloseButtonClick = (event) => {
// 		event.stopPropagation();

// 		event.currentTarget.closest('.product-image').classList.remove('active');
// 	};

// 	const handleProductImageClick = (event) => {
// 		if (!event.target.closest('.image')) {
// 			event.currentTarget.classList.remove('active');
// 		}
// 	};

// 	document.querySelectorAll('.cell-scale').forEach(cell => {
// 		cell.addEventListener('click', handleCellClick);
// 	});

// 	document.querySelectorAll('.close-button').forEach(button => {
// 		button.addEventListener('click', handleCloseButtonClick);
// 	});

// 	document.querySelectorAll('.product-image').forEach(productImage => {
// 		productImage.addEventListener('click', handleProductImageClick);
// 	});
// });



//==========Windows adaptive==================

document.addEventListener('turbo:load', function () {	
	if (window.innerWidth < 1025) {
		const typeTabs = document.querySelectorAll('.type-tab');
		const typeContents = document.querySelectorAll('.type-content');

		function showActiveContent() {
			typeTabs.forEach(function (tab) {
				const targetType = tab.getAttribute('data-target');
				const targetContent = document.querySelector(`.type-content.${targetType}`);
				if (tab.classList.contains('active')) {
					if (targetContent) {
						targetContent.classList.add('active');
					}
				} else {
					if (targetContent) {
						targetContent.classList.remove('active');
					}
				}
			});
		}

		showActiveContent();

		const observer = new MutationObserver(showActiveContent);

		typeTabs.forEach(function (tab) {
			observer.observe(tab, { attributes: true });
		});

		typeTabs.forEach(function (tab) {
			tab.addEventListener('click', function () {
				typeTabs.forEach(function (item) {
					item.classList.remove('active');
				});
				tab.classList.add('active');
			});
		});
	


		document.querySelectorAll('.name-tab').forEach((tab) => {
			tab.addEventListener('click', function () {
				document.querySelectorAll('.name-tab').forEach((t) => t.classList.remove('active'));

				tab.classList.add('active');

				const targetClass = tab.getAttribute('data-target');
				const swiperInstance = document.querySelector('.prod-adaptive-swiper-bullets').swiper;

				const targetSlideIndex = Array.from(document.querySelectorAll('.prod-adaptive-swiper-bullets .swiper-slide')).findIndex((slide) => {
					return slide.classList.contains(targetClass);
				});

				if (targetSlideIndex !== -1) {
					swiperInstance.slideTo(targetSlideIndex);
				}
			});
		});

		function updateActiveTab() {
			const swiperInstance = document.querySelector('.prod-adaptive-swiper-bullets').swiper;
			const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
			const activeClasses = activeSlide.classList;

			document.querySelectorAll('.name-tab').forEach((tab) => {
				const targetClass = tab.getAttribute('data-target');
				if (activeClasses.contains(targetClass)) {
					tab.classList.add('active');
				} else {
					tab.classList.remove('active');
				}
			});
		}

		const swiperInstance = document.querySelector('.prod-adaptive-swiper-bullets').swiper;
		swiperInstance.on('slideChange', updateActiveTab);

		updateActiveTab();

		const initialActiveType = document.querySelector('.type-tab.active');
		if (initialActiveType) {
			initialActiveType.click();
		}
	}
});






document.addEventListener('DOMContentLoaded', function () {
	if (window.innerWidth < 1025) {
		let lastActiveTab = null;

		function scrollToActiveTab() {
			const activeTab = document.querySelector('.name-tab.active');
			const container = document.querySelector('.auto-scroll-container');

			if (activeTab && container) {
				if (activeTab !== lastActiveTab) {
					const containerRect = container.getBoundingClientRect();
					const activeTabRect = activeTab.getBoundingClientRect();

					const offset = activeTabRect.left - containerRect.left;
					container.scrollTo({
						left: offset,
						behavior: 'smooth'
					});

					lastActiveTab = activeTab;
				}
			}
		}

		scrollToActiveTab();

		const observer = new MutationObserver(scrollToActiveTab);
		observer.observe(document.querySelector('.auto-scroll-container'), { attributes: true, childList: true, subtree: true });
	}
});






document.addEventListener('turbo:load', function () {
	if (window.innerWidth < 1025) {
		document.querySelectorAll('.type-tab').forEach((tab) => {
			tab.addEventListener('click', function () {
				document.querySelectorAll('.type-tab').forEach((t) => t.classList.remove('active'));

				tab.classList.add('active');

				const targetClass = tab.getAttribute('data-target');
				const swiperInstance = document.querySelector('.prod-adaptive-swiper-bullets').swiper;

				const targetSlideIndex = Array.from(document.querySelectorAll('.prod-adaptive-swiper-bullets .swiper-slide')).findIndex((slide) => {
					return slide.classList.contains(targetClass);
				});

				if (targetSlideIndex !== -1) {
					swiperInstance.slideTo(targetSlideIndex);
				}
			});
		});

		function updateActiveTab() {
			const swiperInstance = document.querySelector('.prod-adaptive-swiper-bullets').swiper;
			const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
			const activeClasses = activeSlide.classList;

			document.querySelectorAll('.type-tab').forEach((tab) => {
				const targetClass = tab.getAttribute('data-target');
				if (activeClasses.contains(targetClass)) {
					tab.classList.add('active');
				} else {
					tab.classList.remove('active');
				}
			});
		}

		const swiperInstance = document.querySelector('.prod-adaptive-swiper-bullets').swiper;
		swiperInstance.on('slideChange', updateActiveTab);

		updateActiveTab();
	}
});


